
import Api from "@/services/Api";
import { defineComponent, Ref, ref, watch } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import Button from "@/components/Button.vue";
import { useLoaderStore } from "@/store/LoaderStore"




export default defineComponent({
  name: "ModalProdutoIpiranga",
  components: {
    Button,
  },
  props: {
    data: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {

    const { showTimeAlert } = useAlert()
    const emitter = useEmitter()
    const produto: Ref<any> = ref({});
    const codigoProduto: Ref<string> = ref('');
    const loadSave: Ref<boolean> = ref(false);
    const loadExcluir: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);
    const tentouSalvar = ref(false);
    const loaderStore = useLoaderStore();

    function closeModal() {
      tentouSalvar.value = false
      props.fechaModal && props.fechaModal()

    }

    function atualizaNovoProdutoLista(product) {
      emitter.emit('atualizaNovoProdutoLista', { product })
    }

    function removeProdutoLista(product) {
      emitter.emit('removeProdutoLista', { product })
    }

    emitter.on("enviaDadosModal", (valor: any) => {
      let { created_at, updated_at, ...newProduto } = valor.product
      produto.value = newProduto
    })

    async function deleteProduct() {

      try {
        loadExcluir.value = true

        const { data, message } = await Api.delete("deleteProdutoIpiranga", { idProduct: produto.value.id })

        if (!data) {
          dialogVisible.value = false;
          closeModal()
          return showTimeAlert(message, "error")
        }

        removeProdutoLista(data)

        dialogVisible.value = false;
        closeModal()

        return showTimeAlert('Produto deletado com sucesso!', "success")

      } catch (error) {
        console.log('error', error)
      } finally {
        loadExcluir.value = false
      }
    }

    async function salvarProduto() {
      tentouSalvar.value = true;

      if (!produto.value.descricaoCurtaProduto || !produto.value.descricaoLongaProduto) {
        return showTimeAlert('campos obrigatórios estão vazios', "error");
      }

      try {
        loadSave.value = true

        const { data, message } = await Api.post("novoProdutoIpiranga", { product: produto.value })

        if (!data) return showTimeAlert(message, "error");

        //Atualizar lista de produtos
        atualizaNovoProdutoLista(data)

        closeModal()

        return showTimeAlert('Produto salvo com sucesso!', "success")

      } catch (error) {
        console.log("error", error)
      } finally {
        loadSave.value = false
      }
    }

    async function getCorrespondencia() {
      loaderStore.open()
      try {
        const { data, errr, message } = await Api.get('getProdutoIpiranga', { codigoProduto: codigoProduto.value })

        if (data) {
          const { codigoProduto, descricaoCurtaProduto, descricaoLongaProduto, id, ...restanteDosDadosDoProduto } = data
          produto.value = restanteDosDadosDoProduto
        } else {
          showTimeAlert(message, 'error')
        }
      } catch (error) {
        console.log("error", error)
      } finally {
        loaderStore.close()
      }
    }

    watch(() => props.data, async () => { tentouSalvar.value = false }, { deep: true })

    return {
      produto,
      closeModal,
      deleteProduct,
      salvarProduto,
      loadSave,
      loadExcluir,
      dialogVisible,
      getCorrespondencia,
      codigoProduto,
      tentouSalvar
    };
  },
});
