
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
} from "vue";
import ModalProdutoIpiranga from "./components/ModalProdutoIpiranga.vue";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter, useRoute, LocationQueryValue } from "vue-router";
import { Modal } from "bootstrap";
import Pagination from "@/components/Pagination.vue";
import Api from "@/services/Api";
import useEmitter from "@/composables/Emmiter";

interface Input {
  cnpj: string,
  razao_social: string,
  estado: string,
  consultor: string
}


export default defineComponent({
  name: "ProdutosIpiranga",

  components: {
    ModalProdutoIpiranga,
    Title,
    Button,
    Pagination,
  },

  setup() {
    const router = useRouter();

    const route = useRoute();
    const emitter = useEmitter();

    const page: Ref<number> = ref(1)
    const perPage: Ref<number> = ref(10);

    const limite: Ref<number> = ref(20);

    const produtosArray: Ref<any[]> = ref([]);
    const totalProdutos: Ref<number> = ref(0);


    const produtoModal: Ref<any> = ref({});
    const modalProduto: Ref<any> = ref();

    const loadProdutos: Ref<boolean> = ref(false);

    //refs recebidos do FilterUser
    const input: Ref<Input> = ref({
      cnpj: "",
      razao_social: "",
      estado: "",
      consultor: ""
    });

    const idLoja: Ref<LocationQueryValue[] | string | null> = ref(null)
    const lojaFixada: Ref<any> = ref(null)
    const fastFind: Ref<string> = ref('');


    function openModal(produto?) {
      produtoModal.value = produto || {}
      enviaDadosModal(produtoModal.value)
      modalProduto.value.show()
    }

    async function fechaModal() {
      modalProduto.value.hide()
    }


    async function getProdutos() {
      // const { cnpj, razao_social, estado, consultor } = input.value
      loadProdutos.value = true
      try {
        const { data, error, message } = await Api.get("getProdutosIpiranga", {
          page: page.value,
          perPage: perPage.value,
          search: fastFind.value,
          // codigoProduto:
        })

        produtosArray.value = data.produtos
        totalProdutos.value = data.total
        console.log(produtosArray.value)

      } catch (error) {
        console.log("error", error)
      } finally {
        loadProdutos.value = false
      }
    }


    // emitter.on("Filter-LojasValidacaoJetoil", (valor: any) => {
    //   if (valor) input.value = valor;
    //   page.value = 1
    //   getProdutos()
    // })

    emitter.on("atualizaNovoProdutoLista", (valor: any) => {

      const index = produtosArray.value.findIndex(({ id }) => id === valor.product.id)

      if (index !== -1) produtosArray.value[index] = { ...valor.product }
      else produtosArray.value.unshift({ ...valor.product })

    })

    emitter.on("removeProdutoLista", (valor: any) => {

      const index = produtosArray.value.findIndex(({ id }) => id === valor.product.id)

      if (index !== -1) produtosArray.value.splice(index, 1)

    })

    function enviaDadosModal(product) {
      emitter.emit('enviaDadosModal', { product })
    }


    function buscar() {
      //função para buscar produtos
      if (fastFind.value.length > 2 || !fastFind.value) {
        page.value = 1
        getProdutos()
      }
    }

    function handlePageChange(pageParam) {
      page.value = pageParam;
      getProdutos();
    }

    onMounted(async () => {
      modalProduto.value = new Modal(document.querySelector(`#ModalProdutoIpiranga`));
      getProdutos()
    })

    return {
      produtosArray,
      limite,
      history,
      openModal,
      fechaModal,
      router,
      loadProdutos,
      getProdutos,
      produtoModal,
      idLoja,
      lojaFixada,
      fastFind,
      buscar,
      route,
      handlePageChange,
      totalProdutos
    };
  },
});
